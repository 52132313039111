// If you want to override variables do it here
@import "variables";


@import "layout";

// If you want to add custom CSS you can put it here.
@import "custom";
// @import url("https://fonts.googleapis.com/css2?family=Dancing+Script:wght@700&family=Montserrat:wght@400;600;700&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: var(--body-font) !important;
}

.clear-width {
  &>* {
    width: auto;
    margin-left: 10px;
  }
}

.mx-w-80 {
  &>div {
    max-width: 80% !important;
  }
}

.mx-w-60 {
  &>div {
    max-width: 60% !important;
  }
}

.w-10 {
  width: 10% !important;
}

.w-15 {
  width: 15% !important;
}

.ant-picker-dropdown,
.ant-select-dropdown {
  z-index: 1100 !important;
}

.scroll-custom {
  overflow: scroll;

  &::-webkit-scrollbar-track {
    width: 8px;
    height: 8px;
    border-radius: 10px;
    background-color: #F5F5F5;
  }

  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    background-color: #F5F5F5;
  }

  &::-webkit-scrollbar-thumb {
    width: 8px;
    height: 8px;
    border-radius: 10px;
    background-color: #979797;
  }
}

.required::after {
  content: '*';
  color: red;
  margin-left: 3px;
}

.btn:focus {
  outline: none;
  box-shadow: none;
}

@media screen and (max-width: 578px) {
  .single-modal {
    &>div {
      max-width: 80% !important;
    }
  }
}

.cl-mint,
.cl-mint:focus,
.cl-mint:hover,
.cl-mint:active {
  background-color: #1abc9c;
  border: #1abc9c
}